import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>For accessibility assistance, contact Transit:</h2>
      <dl className="alt">
        {/* <dt>Address</dt> */}
        {/* <dd>1234 Somewhere Road &bull; Nashville, TN 00000 &bull; USA</dd> */}
        <dt>Phone</dt>
        <dd>
          <span className="phone">
            <a href="tel:+18076843744">807-684-3744</a>
          </span>
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:transit@thunderbay.ca">transit@thunderbay.ca</a>
        </dd>
      </dl>
      {/* <ul className="icons">
                <li><a href="#" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon fa-facebook alt"><span className="label">Facebook</span></a></li>
                <li><a href="#" className="icon fa-instagram alt"><span className="label">Instagram</span></a></li>
                <li><a href="#" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
                <li><a href="#" className="icon fa-dribbble alt"><span className="label">Dribbble</span></a></li>
            </ul> */}
    </section>
    <section>
      <h2>Need a lift?</h2>
      <p>
        If you require assistance to attend any public meeting, contact Transit
        by January 31 to arrange transportation.
      </p>
      {/* <ul className="actions">
                <li><Link to="/generic" className="button">Learn More</Link></li>
            </ul> */}
    </section>

    {/* <p className="copyright">&copy; Untitled. Design: <a href="https://html5up.net">HTML5 UP</a>.</p> */}
  </footer>
)

export default Footer
